//TODO: use the same laithwaites theme as site-laithwaites (in a way that avoids duplication)
import { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsLW, buildThemeWidgetStylesLW, buildThemeVarsLW } from 'dw-uk-law/theme';

const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => {
      return merge({}, buildThemeVarsLW(vars));
    },
    (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
      return buildThemeMixinsLW(vars);
    },
    (vars: Omit<Theme, 'widgets'>) => {
      return merge({}, buildThemeWidgetStylesLW(vars));
    },
  );

export default createTheme;
